
var lastHoRateChangedValue = null;
var lastMarginChangedValue = null;
$(document).ready(function () {
  var filterData = {};
  var payoutAnyWhere = $("#rate-payout-anywhere").text();
  let payout_anywhere_state = 0;
  $(document).on("blur", "#rate-payout-anywhere", function () {
    payout_anywhere_state = 1;
  });
  $(document).on("change", "#rate-payout-anywhere", function () {
    payoutAnyWhere = $(this).val();
    $("#payout_anywhere").val($(this).val());
  });
  const dropDownSetup = function (param, component, key) {
    const componentId = component.attr('id');
    const label = (componentId === 'rate-dest-country-select' || componentId === 'rate-currency-select') ? 'Please Select' : 'All'
    const selectedValue = filterData[key];
    component.html("<option value='' selected>" + label + "</option>");

    if (param.length < 1) {
      delete filterData[key];
    } else {
      if (key === "delivery_type") {
        component
          .empty()
          .append("<option  selected value>" + label + "</option>");
        Object.keys(param).forEach(function (optionKey) {
          const optionText = optionKey;
          const optionValue = param[optionKey];
          const selected = filterData[key] == optionValue ? "selected" : "";
          component.append(
            `<option value="${optionValue}" ${selected}>${optionText}</option>`
          );
        });
      } else {
        for (let i = 0; i < param.length; i++) {
          const optionText = param[i]["entity_registration"]
            ? param[i]["entity_registration"]["name"]
            : param[i]["currency"]
              ? param[i]["currency"]["name"]
              : param[i]["name"];
          const optionValue = param[i]["id"];
          const selected = selectedValue == optionValue ? "selected" : "";

          component.append(
            `<option value="${optionValue}" ${selected}>${optionText}</option>`
          );
        }
      }
    }
  };
  const setHiddenData = function () {
    const destinationCountryId = $("#rate-dest-country-select").val();
    const localCurrencyId = $("#rate-currency-select").val();
    const correspondentId = $("#rate-correspondent-select").val();
    const deliveryType = $("#rate-delivery-type").val();
    const productId = $("#rate-product-select").val();

    $('#destination_country_id').val(destinationCountryId);
    $('#local_currency_id').val(localCurrencyId);
    $('#correspondent_id').val(correspondentId);
    $('#delivery_type').val(deliveryType);
    $('#product_id').val(productId);
    $('#payout_anywhere').val(payoutAnyWhere);

  }
  const updateCurrencyRates = function (currencyRates, payout_anywhere) {
    $(".get-customer-charges").empty();
    $(".select-all").prop("checked", false);
    let formContent = "";

    if (currencyRates && currencyRates.length > 0) {

      for (let x = 0; x < currencyRates.length; x++) {
        formContent += `
         <tr class="currency-rate-${currencyRates[x].id}">
          <td class="text-center">
            <input type="checkbox" value="${currencyRates[x].agent_id}" name="currency_rate[${x}][agent_id]" id="currency_rate_updated_${x}" class = "sub-checkboxes" ${currencyRates[x].checked ? "checked" : ""
          }>
          </td>
          <td class="text-center">
            ${currencyRates[x].agent
            ? currencyRates[x].agent.entity_registration.name
            : ""
          }
          </td>
          <td class="text-center">
          <input type="text" name="currency_rate[${x}][ho_rate]" value="${currencyRates[x].ho_rate
          }" class="form-control rate-input ho-rate rate-change-input" id="currency_rate_ho_rate-${x}">
        </td>
        <td class="text-center">
          <input type="text" name="currency_rate[${x}][margin]" value="${currencyRates[x].margin
          }" class="form-control rate-input margin rate-change-input" id="currency_rate_margin-${x}">
        </td>
        <td class="text-center">
          <input type="text" name="currency_rate[${x}][agent_cost_rate]" value="${currencyRates[x].agent_cost_rate
          }" class="form-control rate-input agent-cost-rate rate-change-input" id="currency_rate_agent_cost_rate-${x}">
        </td>
        </tr>`;
      }
      $(".get-customer-charges").html(formContent);
      $('.get-customer-charges .rate-change-input').on('change', function () {
        const j_el = $(this.parentElement.parentElement).find('input:checkbox');
        if (!j_el) return;
        if (j_el.is(':checked')) return;
        j_el.prop('checked', true);
      });

      // $(".get-customer-charges input[type='checkbox']").on(
      //   "change",
      //   function () {
      //     var isChecked = $(this).is(":checked");
      //     var hiddenInputs = $(this)
      //       .closest("tr")
      //       .find("td:last-child input[type='hidden']");
      //     $(this)
      //       .closest("tr")
      //       .find(".rate-input")
      //       .prop("disabled", !isChecked);
      //     hiddenInputs.prop("disabled", !isChecked);
      //   }
      // );
      $(".select-all").on("change", function () {
        const isChecked = $(this).is(":checked");
        $(".get-customer-charges input[type='checkbox']").prop(
          "checked",
          isChecked
        );
        // $(
        //   ".get-customer-charges .rate-input, .get-customer-charges input[type='hidden']"
        // ).prop("disabled", !isChecked);
      });
      // $("#rate-payout-anywhere").val(currencyRates[0].payout_anywhere).trigger('change');
      payout_anywhere_state = 0;
      $("#rate-payout-anywhere").val(payout_anywhere).trigger('change');
      setTimeout(function () {
        setHiddenData();
      }, 100);
    }
  };
  function fetchData(data) {
    $('#spinner-modal-trigger').trigger('click');

    return new Promise(function (resolve, reject) {
      $.ajax({
        url: "/currency_rates",
        type: "GET",
        dataType: "json",
        data: data,
        error: function (xhr, status, error) {
          const e = "AJAX Error: " + status + error;
          console.error(e);
          $('#spinner-modal button').trigger('click');
          reject(e);
        },
        success: function (response) {
          // console.log(response);
          lastHoRateChangedValue = null;
          lastMarginChangedValue = null;
          if ($(".apply-all").is(":checked")) {
            $(".apply-all").prop("checked", false);
          }
          if (response.currencies)
            dropDownSetup(
              response.currencies,
              $("#rate-currency-select"),
              "currency"
            );
          if (response.correspondents)
            dropDownSetup(
              response.correspondents,
              $("#rate-correspondent-select"),
              "correspondent"
            );
          if (response.delivery_types)

            dropDownSetup(
              response.delivery_types,
              $("#rate-delivery-type"),
              "delivery_type"
            );
          if (response.products)
            dropDownSetup(
              response.products,
              $("#rate-product-select"),
              "product"
            );
          if (response.currency_rates) {
            updateCurrencyRates(response.currency_rates, response.payout_anywhere);
          }
          $('#spinner-modal button').trigger('click');
          resolve();
        },
      });
    });
  }

  $(document).on("change", "#rate-dest-country-select", function () {
    const destination_country = $(this).val();
    filterData.destination_country = destination_country;
    delete filterData.currency;
    delete filterData.correspondent;
    delete filterData.delivery_type;
    delete filterData.product;
    fetchData(filterData).then(function () { });
  });

  $(document).on("change", "#rate-currency-select", function () {
    const currency = $(this).val();
    filterData.currency = currency;
    delete filterData.correspondent;
    delete filterData.delivery_type;
    delete filterData.product;
    fetchData(filterData).then(function () { });
  });

  $(document).on("change", "#rate-correspondent-select", function () {
    const correspondent = $(this).val();
    filterData.correspondent = correspondent;
    delete filterData.delivery_type;
    delete filterData.product;
    fetchData(filterData).then(function () { });
  });

  $(document).on("change", "#rate-delivery-type", function () {
    const delivery_type = $(this).val();
    filterData.delivery_type = delivery_type;
    delete filterData.product;
    fetchData(filterData).then(function () { });
    if ($(this).val() == 2) {
      $(".payout").hide().find("input").removeAttr("name").prop("disabled", true); // Remove name and disable
    } else {
      $(".payout").show().find("input").attr("name", "payout_anywhere").prop("disabled", false); // Restore name and enable
    }


  });

  $(document).on("change", "#rate-product-select", function () {
    const product = $(this).val();
    filterData.product = product;
    fetchData(filterData).then(function () { });
  });
  $(document)
    .on("change", ".checkbox-id", function () {
      $(".submit-btn").prop(
        "disabled",
        $(".checkbox-id").filter(":checked").length < 1
      );
    })
    .change();

  // $(function () {
  //   $(".ho-rate, .margin").on("keydown keyup", subtract);
  //   function subtract() {
  //    var ids =  this.id[15]
  //    console.log(ids)
  //     $(`.agent-cost-rate-${ids}`).val(
  //       Number($(`.ho-rate-${ids}`).val()) - Number($(`.margin-${ids}`).val())
  //     );
  //   }
  // });
  $(document).on("keydown keyup", ".ho-rate, .margin", function () {
    var ids = this.id.split("-")[1];
    $(`#currency_rate_agent_cost_rate-${ids}`).val(
      Number($(`#currency_rate_ho_rate-${ids}`).val()) - Number($(`#currency_rate_margin-${ids}`).val())
    );
  });

  //currency variance checkbox

  $(document).on("input", ".ho-rate, .margin", function () {
    var ids = this.id.split("-")[1];
    $(`#currency_rate_agent_cost_rate-${ids}`).val(
      Number($(`#currency_rate_ho_rate-${ids}`).val()) - Number($(`#currency_rate_margin-${ids}`).val())
    );
  });


  $(document).on("change", ".ho-rate", function () {
    lastHoRateChangedValue = $(this).val();
  });
  $(document).on("change", ".margin", function () {
    lastMarginChangedValue = $(this).val();
  });

  $(".apply-all").on("change", function () {
    $(".select-all").prop("checked", $(this).is(":checked")).trigger("change");
    $(".sub-checkboxes").trigger("change");

    if ($(this).is(":checked")) {
      lastHoRateChangedValue !== null &&
        $(".ho-rate").not(this).val(lastHoRateChangedValue).trigger("input");
      lastMarginChangedValue !== null &&
        $(".margin").not(this).val(lastMarginChangedValue).trigger("input");
    }
  });

  function showAlert(title, message) {
    const content = `
    <ul class="flex flex-wrap gap-x-4 items-center">
      <li>${message}</li>
    </ul>
      `;
    $('#notification-modal .modal-title').html(title);
    $('#notification-modal .modal-body').html(content);
    $('#notification-modal-button').trigger('click');
  }

  const submitForm = $('#currency-rate-update-all');
  submitForm.on('ajax:beforeSend', function (event) {
    const sleected_count = $(".get-customer-charges input[type='checkbox']:checked").length;
    if (sleected_count < 1 && payout_anywhere_state == 0) {
      showAlert('Alert', 'Nothing is selected or payout anywhere rate not changed');
      event.preventDefault();
      return;
    }
    $('#spinner-modal-trigger').trigger('click');
  });
  submitForm.on('ajax:success', function (event) {
    payout_anywhere_state = 0;
    $('#spinner-modal button').trigger('click');
    $(".get-customer-charges input[type='checkbox']").prop(
      "checked",
      false
    );
    setTimeout(() => {
      showAlert('Success', 'Currency Rates saved successfuly')
    }, 300);
  });
  submitForm.on('ajax:error', function () {
    $('#spinner-modal button').trigger('click');
    setTimeout(() => {
      showAlert('Error', 'Error! While trying to save Currency Rates');
    }, 300);
  });

  submitForm.on('ajax:complete', function (event) {
    // Re-enable the submit button after the request completes
    $(event.target).children('button[type="submit"]').prop('disabled', false);
  });
});
